import Vue from 'vue'
import VueRouter from 'vue-router'
import { getAuth } from '../utils/auth.utils'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Settings from './modules/Settings'
import SettingsPackage from './modules/SettingsPackage'
import Vendors from './modules/Vendors'
import Tours from './modules/Tours'
import Members from './modules/Members'
import Orders from './modules/Orders'
import Blogs from './modules/Blogs'
import Privileges from './modules/Privileges'
import Contents from './modules/Contents'
import Reports from './modules/Reports'
import PrivateTours from './modules/PrivateTours'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Login
  },
  Settings,
  SettingsPackage,
  Vendors,
  Tours,
  Members,
  Orders,
  Blogs,
  Privileges,
  Contents,
  Reports,
  PrivateTours
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.name !== 'Login' && !getAuth()) {
    return next({
      name: 'Login'
    })
  }
  return next()
})

export default router
