import auth from '../../middlewares/auth'

export default {
  path: '/private-tours',
  component: () => import('@/views/private-tours/index.vue'),
  children: [
    {
      path: '',
      name: 'PrivateTourList',
      component: () => import('@/views/private-tours/pages/List.vue'),
      meta: {
        middleware: auth
      }
    },
    {
      path: 'detail/:id',
      name: 'PrivateTourDetail',
      component: () => import('@/views/private-tours/pages/Detail.vue'),
      meta: {
        middleware: auth
      }
    }
  ]
}
