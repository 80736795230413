import auth from '../../middlewares/auth'

export default {
  path: '/privileges',
  component: () => import('@/views/privileges/index.vue'),
  children: [
    {
      path: '',
      name: 'PrivilegeList',
      component: () => import('@/views/privileges/pages/List.vue'),
      meta: {
        middleware: auth
      }
    },
    {
      path: 'create',
      name: 'PrivilegeCreate',
      component: () => import('@/views/privileges/pages/Form.vue'),
      meta: {
        middleware: auth
      }
    },
    {
      path: 'edit/:id',
      name: 'PrivilegeEdit',
      component: () => import('@/views/privileges/pages/Form.vue'),
      meta: {
        middleware: auth
      }
    },
    {
      path: 'detail/:id',
      name: 'PrivilegeDetail',
      component: () => import('@/views/privileges/pages/Detail.vue'),
      meta: {
        middleware: auth
      }
    }
  ]
}
