import auth from '../../middlewares/auth'

export default {
  path: '/reports',
  component: () => import('@/views/reports/index.vue'),
  children: [
    {
      path: '',
      name: 'ReportList',
      component: () => import('@/views/reports/pages/List.vue'),
      meta: {
        middleware: auth
      }
    },
    {
      path: 'sales-report',
      name: 'SalesReport',
      component: () => import('@/views/reports/pages/SalesReport.vue'),
      meta: {
        middleware: auth
      }
    },
    {
      path: 'sales-report/:id',
      name: 'SalesReportDetail',
      component: () => import('@/views/reports/pages/SalesReportDetail.vue'),
      meta: {
        middleware: auth
      }
    },
    {
      path: 'sales-report/:id/package/:startDate',
      name: 'SalesReportPackageDetail',
      component: () => import('@/views/reports/pages/SalesReportPackageDetail.vue'),
      meta: {
        middleware: auth
      }
    },
    {
      path: 'vendors-sales-report',
      name: 'VendorsSalesReport',
      component: () => import('@/views/reports/pages/VendorsSalesReport.vue'),
      meta: {
        middleware: auth
      }
    },
    {
      path: 'vendors-sales-report/:id',
      name: 'VendorsSalesReportDetail',
      component: () => import('@/views/reports/pages/VendorsSalesReportDetail.vue'),
      meta: {
        middleware: auth
      }
    }
  ]
}
