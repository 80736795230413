import auth from '../../middlewares/auth'

export default {
  path: '/contents',
  component: () => import('@/views/contents/index.vue'),
  children: [
    {
      path: '',
      name: 'Contents',
      component: () => import('@/views/contents/pages/Contents.vue'),
      meta: {
        middleware: auth
      }
    },
    {
      path: 'home-page',
      name: 'ContentsHomePage',
      component: () => import('@/views/contents/pages/home-page/index.vue'),
      meta: {
        middleware: auth
      }
    },
    {
      path: 'about-us',
      name: 'ContentsAboutUs',
      component: () => import('@/views/contents/pages/about-us/index.vue'),
      meta: {
        middleware: auth
      }
    },
    {
      path: 'terms-and-conditions',
      name: 'ContentsTerms',
      component: () => import('@/views/contents/pages/terms/index.vue'),
      meta: {
        middleware: auth
      }
    },
    {
      path: 'privacy-policies',
      name: 'ContentsPrivacy',
      component: () => import('@/views/contents/pages/privacy-policies/index.vue'),
      meta: {
        middleware: auth
      }
    },
    {
      path: 'cookies-consents',
      name: 'ContentsCookies',
      component: () => import('@/views/contents/pages/cookies-consents/index.vue'),
      meta: {
        middleware: auth
      }
    },
    {
      path: 'how-to-reserves',
      name: 'ContentsHowTo',
      component: () => import('@/views/contents/pages/how-to-reserves/index.vue'),
      meta: {
        middleware: auth
      }
    },
    {
      path: 'help-center',
      name: 'ContentsHelpCenter',
      component: () => import('@/views/contents/pages/help-center/index.vue'),
      meta: {
        middleware: auth
      }
    },
    {
      path: 'partners',
      name: 'ContentsPartner',
      component: () => import('@/views/contents/pages/partners/index.vue'),
      meta: {
        middleware: auth
      }
    },
    {
      path: 'partners/create',
      name: 'ContentsPartnerCreate',
      component: () => import('@/views/contents/pages/partners/PartnerForm.vue'),
      meta: {
        middleware: auth
      }
    },
    {
      path: 'partners/edit/:id',
      name: 'ContentsPartnerEdit',
      component: () => import('@/views/contents/pages/partners/PartnerForm.vue'),
      meta: {
        middleware: auth
      }
    }
  ]
}
