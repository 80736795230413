<template>
  <v-app>
    <snackbar />
    <confirm-dialog />
    <loading />
    <v-navigation-drawer
      v-model="drawer"
      :mini-variant.sync="mini"
      permanent
      touchless
      app
      color="white">
      <v-list-item class="px-2">
        <v-list-item-title class="text-center">
          <v-img
            class="my-5 mx-auto"
            width="100px"
            src="./assets/new-logo.png" />
        </v-list-item-title>

        <v-btn
          icon
          @click.stop="mini = !mini">
          <v-icon color="primary">
            mdi-menu-open
          </v-icon>
        </v-btn>
      </v-list-item>

      <v-divider class="divider" />

      <v-list-item class="px-2">
        <div class="user-container">
          <div
            class="d-flex flex-row align-center"
            style="gap: 8px;">
            <div class="user-image text-uppercase">
              <span>
                {{ username[0] }}
              </span>
            </div>
            <div class="d-flex flex-column jutify-space-between">
              <span class="user-text text-truncate">
                {{ username }}
              </span>
              <span class="user-sub-text text-capitalize">
                {{ role }}
              </span>
            </div>
          </div>
          <div
            class="user-button"
            @click="gotoSetting()">
            <v-icon color="white">
              mdi-cog-outline
            </v-icon>
          </div>
        </div>
      </v-list-item>

      <v-list
        dense
        expand>
        <v-list-item-group
          v-for="(item, index) in items"
          :key="`items-${index}`"
          no-action
          mandatory
          color="primary">
          <v-list-group
            v-if="item.subMenus"
            :prepend-icon="mini ? item.icon : null"
            no-action
            :value="false"
            :ripple="false"
            color="secondary">
            <template #activator>
              <v-list-item-content>
                <v-list-item-title>
                  <v-subheader class="white--text">
                    {{ item.title }}
                  </v-subheader>
                </v-list-item-title>
              </v-list-item-content>
            </template>
            <v-divider class="divider mb-2" />
            <sub-menu
              v-for="(subMenu, i) in item.subMenus"
              :key="`${item.title}-${subMenu.title}-${i}`"
              :title="subMenu.title"
              :to="subMenu.to"
              :icon="subMenu.icon"
              icon-prepend />
          </v-list-group>
          <sub-menu
            v-else
            :title="item.title"
            :to="item.to"
            :icon="item.icon"
            icon-prepend />
        </v-list-item-group>
      </v-list>

      <template #append>
        <v-btn
          v-if="mini"
          color="primary"
          icon
          class="mb-2 ml-2">
          <v-icon>
            mdi-logout
          </v-icon>
        </v-btn>
        <div
          v-else
          class="pa-2">
          <v-btn
            color="primary"
            block
            to="/logout">
            <v-icon>
              mdi-logout
            </v-icon>
            <span
              class="text-capitalize"
              style="font-size: 16px;">
              ออกจากระบบ
            </span>
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>

    <v-main style="background-color: #F3F3F3;">
      <v-app-bar
        dense
        app
        color="white"
        elevation="0">
        <span class="primary--text">
          {{ menuTitle }}
        </span>
      </v-app-bar>
      <v-fade-transition mode="out-in">
        <v-container
          fluid
          style="border-top: 1px solid #E0E0E0;">
          <router-view />
        </v-container>
      </v-fade-transition>
    </v-main>
  </v-app>
</template>

<script>
import { mapActions } from 'vuex'
import UsersProvider from './resources/users.provider'
import Menus from './assets/json/menus.json'
import SubMenu from './components/SubMenu.vue'
import ConfirmDialog from './components/ConfirmDialog.vue'
import Snackbar from './components/Snackbar.vue'
import Loading from './components/Loading.vue'
import { getAuthDecode } from './utils/auth.utils'

const UsersService = new UsersProvider()

export default {
  name: 'App',
  components: {
    SubMenu,
    ConfirmDialog,
    Snackbar,
    Loading
  },
  data: () => ({
    drawer: true,
    mini: false
  }),
  computed: {
    user () {
      return this.$store.getters['User/user']
    },
    username () {
      return this.user?.username || 'alltotrips'
    },
    role () {
      return this.user?.role || 'alltotrips'
    },
    menuTitle () {
      const found = Menus.find((m) => m.to === this.$route.name)

      return found?.title || ''
    },
    items () {
      const filtered = Menus.filter((m) => m.showOnNav)

      return filtered.filter((m) => m.roles.includes(this.role))
    }
  },
  mounted () {
    this.getUser()
  },
  methods: {
    ...mapActions({
      setUser: 'User/setUser',
      setLoading: 'Loading/setLoading',
      setSnackbar: 'Snackbar/setSnackbar'
    }),
    async getUser () {
      const decodeUser = getAuthDecode()

      if (decodeUser) {
        try {
          this.setLoading(true)
          const { data: user } = await UsersService.getUserById(decodeUser.sub)

          if (!user) {
            this.$router.push({ name: 'Login' })
          }
          this.setUser(user)
        } catch (error) {
          console.error('decodeUser', error)
          this.setSnackbar({
            active: true,
            text: error?.message || error,
            type: 'error',
            timeout: 2000
          })
        } finally {
          this.setLoading(false)
        }
      }
    },
    gotoSetting () {
      if (this.role === 'vendor') {
        this.$router.push({
          name: 'VendorDetail',
          params: {
            id: this.user.vendor.id
          }
        })
      } else if (this.role === 'administrator') {
        this.$router.push({
          name: 'SettingsUserEdit',
          params: {
            id: this.user.id
          }
        })
      }
    }
  }
}
</script>

<style scoped>
.divider {
  background-color: #E0E0E0;
}
.user-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background-color: #F7F6F9;
  margin: 8px 0;
  padding-left: 8px;
  width: 100%;
}
.user-image {
  border-radius: 32px;
  background-color: #636e72;
  width: 32px;
  height: 32px;

  display: flex;
  justify-content: center;
  align-items: center;
}
.user-image span {
  color: white;
  font-size: 1.25rem;
}
.user-text {
  font-size: 16px;
  font-weight: bold;
  color: #026EAA;
}
.user-sub-text {
  font-size: 14px;
  color: #9B9DA2;
}
.user-button {
  background-color: #026EAA;
  height: 100%;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 8px 6px;
  cursor: pointer;
}
.text-center {
  text-align: center;
}
</style>

<style>
* {
  font-family: 'Sarabun', sans-serif;
}
.table-header {
  background-color: #026EAA !important;
  color: white !important;
  font-size: 16px !important;
}
.table-header:first-child {
  border-top-left-radius: 8px;
}
.table-header:last-child {
  border-top-right-radius: 8px;
}
.limit-text {
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
