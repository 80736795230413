import auth from '../../middlewares/auth'

export default {
  path: '/blogs',
  component: () => import('@/views/blogs/index.vue'),
  children: [
    {
      path: '',
      name: 'BlogList',
      component: () => import('@/views/blogs/pages/List.vue'),
      meta: {
        middleware: auth
      }
    },
    {
      path: 'create',
      name: 'BlogCreate',
      component: () => import('@/views/blogs/pages/Form.vue'),
      meta: {
        middleware: auth
      }
    },
    {
      path: 'edit/:id',
      name: 'BlogEdit',
      component: () => import('@/views/blogs/pages/Form.vue'),
      meta: {
        middleware: auth
      }
    },
    {
      path: 'detail/:id',
      name: 'BlogDetail',
      component: () => import('@/views/blogs/pages/Detail.vue'),
      meta: {
        middleware: auth
      }
    }
  ]
}
